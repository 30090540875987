<template>
    <div>
        <el-row style="display: flex; align-items: center;">
            <el-col :span="8" class="component-title" style="cursor: pointer;" v-if="!isMobile()">
                User Feedback
            </el-col>
            <el-col v-else>
                &nbsp;
            </el-col>
            <el-col :offset="14" :span="2" v-if="!isMobile()">
                <profile-avatar></profile-avatar>
            </el-col>
        </el-row>
        <div :style="isMobile()?{height: '75vh'}:{height: '87vh'}"
             style="overflow-y: auto; overflow-x: hidden; padding: 0 0 3vh;">
            <el-row>
                <data-tables :data="feedbacks" :page-size="5" :filters="filters"
                             style="width: 100%;"
                             :pagination-props="{ pageSizes: [5, 10] }">
                    <el-row slot="tool" style="margin: 10px 0">
                        <el-col :xl="5" :lg="5" :md="8" :sm="24" :xs="24">
                            <el-input placeholder="Search question" v-model="filters[0].value"></el-input>
                        </el-col>
                    </el-row>
                    <el-table-column label="Student" :prop="'user'" width="200"
                                     :sortable="true">
                    </el-table-column>
                    <el-table-column label="Satisfied Recommendation" :prop="'satisfied_recommendation'" width="150"
                                     :sortable="true"></el-table-column>
                    <el-table-column label="Recommend Friend" :prop="'recommend_friend'" width="150"
                                     :sortable="true"></el-table-column>
                    <el-table-column label="Expected Results" :prop="'expected_results'" width="150"
                                     :sortable="true"></el-table-column>
                    <el-table-column label="Expected Results Reasons" :prop="'expected_results_reasons'" width="350"
                                     :sortable="true"></el-table-column>
                    <el-table-column label="Any Area didn't appear" :prop="'interested_area'" width="150"
                                     :sortable="true"></el-table-column>
                    <el-table-column label="Interested Area Explanation" :prop="'interested_area_explanation'"
                                     width="350"
                                     :sortable="true"></el-table-column>
                    <el-table-column label="Date" :prop="'created'" width="350"
                                     :sortable="true"></el-table-column>
                </data-tables>
            </el-row>
            <el-row style="float: left;">
                <table class="feedbackAnalysisTab">
                    <tr>
                        <td>Average of user satisfied with recommendations:</td>
                        <td><strong>{{averageSatisfied}}</strong></td>
                    </tr>
                    <tr>
                        <td>Average of user recommending us to his/her friend:</td>
                        <td><strong>{{averageRecommendFriend}}</strong></td>
                    </tr>
                    <tr>
                        <td>Percentage of user received expected results:</td>
                        <td><strong>{{percentageYesExpectedResults}}%</strong></td>
                    </tr>
                    <tr>
                        <td>Percentage of user with missing required interested area in their results:</td>
                        <td><strong>{{percentageYesInterestAreaNot}}%</strong></td>
                    </tr>
                </table>
            </el-row>
        </div>
    </div>
</template>

<script>
  import ProfileAvatar from '../../main_components/ProfileAvatar';

  export default {
    name: 'UserFeedback',
    components: {ProfileAvatar},
    data: function() {
      return {
        feedbacks: [],
        averageSatisfied: 0,
        averageRecommendFriend: 0,
        percentageYesExpectedResults: 0,
        percentageYesInterestAreaNot: 0,
        filters: [
          {
            value: '',
            prop: 'name',
          }],
      };
    },
    mounted() {
      let self = this;
      let url = self.getBaseUrl() + '/accounts/feedback/list';
      self.$store.state.loadingScreen = true;
      $.ajax({
        url: url,
        method: 'GET',
        beforeSend: function(request) {
          request.setRequestHeader('Authorization', 'Token ' + self.getAuthToken());
        },
        success: function(response) {
          self.feedbacks = response;
          self.averageSatisfied = response.reduce((total, next) => total + next.satisfied_recommendation, 0) /
              response.length;
          self.averageRecommendFriend = response.reduce((total, next) => total + next.recommend_friend, 0) /
              response.length;
          self.percentageYesExpectedResults = Math.round(response.filter(function(element) {
            return element.expected_results === 'Yes';
          }).length / response.length * 100);
          self.percentageYesInterestAreaNot = Math.round(response.filter(function(element) {
            return element.interested_area === 'Yes';
          }).length / response.length * 100);
          self.$store.state.loadingScreen = false;
        },
        error: function(error) {
          console.log(error);
          self.$store.state.loadingScreen = false;
        },
      });
    },
  };
</script>

<style scoped>
    .feedbackAnalysisTab tr td {
        text-align: left !important;
        padding: 5px 10px;
    }
</style>
